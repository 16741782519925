import { Colors, Img, Lightning, Utils } from '@lightningjs/sdk'

import { pathJoin } from '../lib'
// const debug = new Debugger('AdeColorIcon')
export interface AdeColorIconProperties {
  color?: number
  icon: string
}
export interface AdeColorIconTemplateSpec
  extends Lightning.Component.TemplateSpec {
  BaseIcon: object
  FocusIcon: object
  focusColor: string | number
  icon: Partial<AdeColorIconProperties>
}

export class AdeColorIcon
  extends Lightning.Component<AdeColorIconTemplateSpec>
  implements
    Lightning.Component.ImplementTemplateSpec<AdeColorIconTemplateSpec>
{
  BaseIcon = this.getByRef('BaseIcon')!
  FocusIcon = this.getByRef('FocusIcon')!
  static override _template() {
    return {
      visible: false,
      BaseIcon: {
        x: 0,
        y: 0,
        alpha: 1,
        color: Colors('text').get(),
      },
      FocusIcon: {
        x: 0,
        y: 0,
        alpha: 0,
        color: Colors('primaryHighlight').get(),
      },
    }
  }

  private _focusAnimation: Lightning.types.Animation | undefined = undefined
  private _animationDuration: number = 0.2

  set animationDuration(x: number) {
    this._focusAnimation = undefined
    this._animationDuration = x
  }
  get animationDuration() {
    return this._animationDuration
  }

  get focusAnimation(): Lightning.types.Animation {
    if (this._focusAnimation) return this._focusAnimation
    this._focusAnimation = this.animation({
      duration: this.animationDuration,
      actions: [
        { t: 'BaseIcon', p: 'alpha', v: { 0: 1, 1: 0 } },
        { t: 'FocusIcon', p: 'alpha', v: { 0: 0, 1: 1 } },
      ],
    })
    return this._focusAnimation
  }

  start() {
    this.focusAnimation.start()
  }
  stop() {
    this.focusAnimation.stop()
  }
  finish() {
    this.focusAnimation.finish()
  }
  private _icon: Partial<AdeColorIconProperties> = {
    color: Colors('text').get(),
  }

  set icon(icon: Partial<AdeColorIconProperties>) {
    if (icon.icon) {
      this.patch({
        visible: true,
        BaseIcon: {
          w: this.w,
          h: this.h,
          texture: Img(
            Utils.asset(pathJoin(['images/icons', icon.icon]))
          ).contain(this.w, this.h),
          color: this.color,
        },
        FocusIcon: {
          w: this.w,
          h: this.h,
          texture: Img(
            Utils.asset(pathJoin(['images/icon', icon.icon]))
          ).contain(this.w, this.h),
          color: this.focusColor,
        },
      })
    }
  }
  get icon() {
    return this._icon!
  }
  private _focusColor: number = Colors('text').get()
  set focusColor(color: number) {
    this.FocusIcon.patch({
      color,
    })
  }
  get focusColor() {
    return this._focusColor
  }
}
