import { Lightning } from '@lightningjs/sdk'
import {
  AdvancedGrid,
  AdvancedGridList,
  AdvancedGridPatch,
} from '../components'
import { SimpleGridRow } from '@adiffengine/engine-types'
import isEqual from 'fast-deep-equal/es6'

export interface PlainGridScreenTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Grid: typeof AdvancedGrid
  content: SimpleGridRow[]
}
export interface PlainGridScreenTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
export class PlainGridScreen
  extends Lightning.Component<
    PlainGridScreenTemplateSpec,
    PlainGridScreenTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<PlainGridScreenTemplateSpec>
{
  Grid = this.getByRef('Grid')!
  static override _template(): Lightning.Component.Template<PlainGridScreenTemplateSpec> {
    return {
      Grid: {
        x: 80,
        y: 80,
        w: 1920 - 160,
        h: 1080 - 160,
        type: AdvancedGrid,
        spacing: 40,
      },
    }
  }
  private _rows: SimpleGridRow[] = []
  set content(rows: SimpleGridRow[]) {
    if (!isEqual(rows, this._rows)) {
      this._rows = rows
      const items: AdvancedGridPatch[] = this._rows.map(r =>
        this.Grid.gridItem({
          listId: r.id,
          content: r,
          type: AdvancedGridList,
          w: this.w,
        })
      )
      this.Grid.patch({ items })
    }
  }
  get content() {
    return this._rows
  }
}
