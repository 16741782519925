import { Colors, Img, Lightning, Registry, Utils } from '@lightningjs/sdk'
import isString from 'lodash-es/isString'
import isFunction from 'lodash-es/isFunction'

import { Debugger } from '../lib'
import { GlowBalls } from './GlowBalls'
const debug = new Debugger('Frame')
debug.enabled = false
// Simple sample of a framed images
export interface FrameTemplateSpec extends Lightning.Component.TemplateSpec {
  Frame: object
  FrameFade: object
  Art: object
  Inset: object
  DoubleInset: object
  Overlay: typeof GlowBalls
  images: string[]
}
export const FRAME_TICK_LENGTH = 4
export const FRAME_SIZE = 50
export const FRAME_INSET_SIZE = 8
export const FRAME_IMAGE_WIDTH = 1920 - (FRAME_SIZE + FRAME_INSET_SIZE) * 2
export const FRAME_IMAGE_HEIGHT = 1080 - (FRAME_SIZE + FRAME_INSET_SIZE) * 2

export type FrameTypeConfig = Lightning.Component.TypeConfig
export class Frame
  extends Lightning.Component<FrameTemplateSpec, FrameTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<FrameTemplateSpec>
{
  Art = this.getByRef('Art')!
  Overlay = this.getByRef('Overlay')!
  Inner1 = this.Overlay.getByRef('Inner1')!
  Inner2 = this.Overlay.getByRef('Inner2')!
  Inner3 = this.Overlay.getByRef('Inner3')!
  static override _template(): Lightning.Component.Template<FrameTemplateSpec> {
    return {
      x: 0,
      y: 0,
      h: 1080,
      w: 1920,
      color: Colors('#F7F7F2').get(),
      rect: true,
      rtt: true,
      Frame: {
        x: FRAME_SIZE,
        y: FRAME_SIZE,
        w: 1920 - FRAME_SIZE * 2,
        h: 1080 - FRAME_SIZE * 2,
        rect: true,
        color: Colors('#E8E8D9').get(),
      },
      FrameFade: {
        x: FRAME_SIZE,
        y: FRAME_SIZE,
        w: 1920 - FRAME_SIZE * 2,
        h: 1080 - FRAME_SIZE * 2,
        rect: true,
        color: Colors('#4C4D2E').alpha(0.3).get(),
        shader: {
          type: Lightning.shaders.FadeOut,
          fade: FRAME_INSET_SIZE * 0.75,
        },
      },
      Art: {
        x: FRAME_SIZE + FRAME_INSET_SIZE,
        y: FRAME_SIZE + FRAME_INSET_SIZE,
        w: FRAME_IMAGE_WIDTH,
        h: FRAME_IMAGE_HEIGHT,
        rect: true,
      },
      Inset: {
        x: FRAME_SIZE + FRAME_INSET_SIZE,
        y: FRAME_SIZE + FRAME_INSET_SIZE,
        w: 1920 - (FRAME_SIZE + FRAME_INSET_SIZE) * 2,
        h: 1080 - (FRAME_SIZE + FRAME_INSET_SIZE) * 2,
        rect: true,
        rtt: true,
        color: 0x00000000,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 0,
          stroke: 1,
          strokeColor: Colors('black').alpha(0.6).get(),
        },
      },
      Overlay: {
        type: GlowBalls,
      },
    }
  }

  override _construct() {
    this.imageEntering = this.imageEntering.bind(this)
  }

  showImage() {
    this.Overlay.setSmooth('alpha', 0, { duration: 1 })
  }

  private _images: string[] = [
    Utils.asset('images/art/shipping.jpg'),
    Utils.asset('images/art/english_black_cocks.jpg'),
  ]
  private _imageIndex = 0
  set images(images: string[]) {
    this._images = images
  }
  get images() {
    return this._images
  }
  nextImage() {
    const lastImage = this._imageIndex
    this._imageIndex++
    if (this._imageIndex === this.images.length) {
      this._imageIndex = 0
    }
    const last = this.Art.childList.getAt(lastImage)
    const next = this.Art.childList.getAt(this._imageIndex)
    debug.info('Get %s and %s', lastImage, this._imageIndex, last, next)
    if (last && isEnterExitElement(last)) {
      debug.info('Calling exit on %s', last)
      last.exit()
    }
    if (next && isEnterExitElement(next)) {
      debug.info('Calling enterin on %s', next)
      next.enter()
    }
  }
  hide() {
    this.clearInterval()
  }
  private _overlayHidden = false
  hideOverlay() {
    if (!this._overlayHidden) {
      this._overlayHidden = true
      this.Overlay.setSmooth('alpha', 0, { duration: 1 })
    }
  }

  imageEntering() {
    this.hideOverlay()
  }

  addImage(image: string) {
    this.Art.childList.add(
      this.stage.c({
        type: FrameImage,
        image,
        signals: {
          entering: this.imageEntering,
        },
      })
    )
  }
  private _interval: ReturnType<typeof Registry.setInterval> | null = null
  override _active() {
    this.clearInterval()
    this.Art.childList.clear()
    this._images.forEach(i => this.addImage(i))
    this._interval = Registry.setInterval(() => {
      this.nextImage()
    }, 4000)
  }
  clearInterval() {
    if (this._interval !== null) Registry.clearInterval(this._interval)
    this._interval = null
  }
  override _inactive() {
    this.clearInterval()
  }
}
export interface EnterExitElement extends Lightning.Element {
  enter(): void
  exit(): void
}

export function isEnterExitElement(
  x: Lightning.Element
): x is EnterExitElement {
  return x && isFunction((x as any).enter) && isFunction((x as any).exit)
}

export interface FrameImageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Image: object
}
export interface FrameImageTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: {
    entering(image: FrameImage): void
    entered(image: FrameImage): void
    exiting(image: FrameImage): void
    exited(image: FrameImage): void
  }
}
export class FrameImage
  extends Lightning.Component<FrameImageTemplateSpec, FrameImageTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<FrameImageTemplateSpec>
{
  public isFrameImage = true
  Image = this.getByRef('Image')!
  static override _template(): Lightning.Component.Template<FrameImageTemplateSpec> {
    return {
      alpha: 0.0001,
      w: FRAME_IMAGE_WIDTH,
      h: FRAME_IMAGE_HEIGHT,
      x: 0,
      y: 0,
      rtt: true,
      rect: true,
      Image: {
        x: 0,
        y: 0,
        w: FRAME_IMAGE_WIDTH,
        h: FRAME_IMAGE_HEIGHT,
      },
    }
  }

  override _construct() {
    this.signalEntering = this.signalEntering.bind(this)
    this.signalEntered = this.signalEntered.bind(this)
    this.signalExiting = this.signalExiting.bind(this)
    this.signalExited = this.signalExited.bind(this)
  }

  private _state: 'ready' | 'entering' | 'entered' | 'exiting' | 'exited' =
    'ready'

  get imageState() {
    return this._state
  }

  signalEntering() {
    this._state = 'entering'
    this.signal('entering', this)
  }
  signalEntered() {
    this._state = 'entered'
    this.signal('entered', this)
  }
  signalExiting() {
    this._state = 'exiting'
    this.signal('exiting', this)
  }

  signalExited() {
    this._state = 'exited'
    this.signal('exited', this)
  }

  enter() {
    debug.info(
      'Entering... %s %s',
      this._image,
      this._state,
      this.attached,
      this.active
    )
    if (this.attached) {
      this.transition('alpha').off('finish', this.signalExited)
      this.transition('alpha').on('finish', this.signalEntered)
      this.signal('entering', this)
      this.patch({
        smooth: {
          alpha: [1, { duration: 1 }],
        },
      })
      debug.info('PAtched %s to show', this._image)
    }
  }
  exit() {
    debug.info('Exit... %s', this._image)
    this.signal('exiting', this)
    this.transition('alpha').off('finish', this.signalEntered)
    this.transition('alpha').on('finish', this.signalExited)
    this.patch({
      smooth: {
        alpha: [0, { duration: 1 }],
      },
    })
  }
  private _image: string | null = null
  set image(image: string | null) {
    debug.info('Starting image with tx loader %s', image)
    this._image = image
    if (isString(image)) {
      this.Image.patch({
        rect: true,
        rtt: true,
        texture: Img(image).cover(FRAME_IMAGE_WIDTH, FRAME_IMAGE_HEIGHT),
      })
    }
  }
  get image() {
    return this._image
  }
}
