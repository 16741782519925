import { Colors, Img, Lightning, Settings } from '@lightningjs/sdk'

import {
  ContentItem,
  DirectionalSignalMap,
  PlayerState,
} from '@adiffengine/engine-types'
import equal from 'fast-deep-equal/es6'
import human from 'humanize-duration'
import { Debugger } from '../lib'
const debug = new Debugger('NextVideoCard')
export interface NextVideoCardTemplateSpec
  extends Lightning.Component.TemplateSpec {
  next: ContentItem | null
  Content: {
    Timer: {
      Progress: object
      Countdown: object
    }
    ContentItem: {
      ImageWrapper: {
        Image: object
        ImageCover: object
        PlayIcon: object
      }
      Text: {
        Title: object
        Description: object
      }
    }
  }
}
const sh = human.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
})

export type NextVideoTrigger = 'timeout' | 'selected'
interface NextVideoCardSignals extends DirectionalSignalMap {
  next(type: NextVideoTrigger): void
}
export interface NextVideoCardTypeConfig
  extends Lightning.Component.TypeConfig {
  SignalMapType: NextVideoCardSignals
}
export class NextVideoCard
  extends Lightning.Component<
    NextVideoCardTemplateSpec,
    NextVideoCardTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<NextVideoCardTemplateSpec>
{
  Content = this.getByRef('Content')!
  Timer = this.Content.getByRef('Timer')!
  ContentItem = this.Content.getByRef('ContentItem')!
  ImageWrapper = this.ContentItem.getByRef('ImageWrapper')!
  Image = this.ImageWrapper.getByRef('Image')!
  Text = this.ContentItem.getByRef('Text')!
  Title = this.Text.getByRef('Title')!
  Description = this.Text.getByRef('Description')!
  Progress = this.Timer.getByRef('Progress')!
  Countdown = this.Timer.getByRef('Countdown')!
  static height = 128
  static width = 400

  static innerHeight = this.height - 20

  static override _template(): Lightning.Component.Template<NextVideoCardTemplateSpec> {
    return {
      alpha: 0.0001,
      Content: {
        w: this.width,
        h: this.height,
        rect: true,
        rtt: true,
        color: 0x00000000,
        clipping: false,
        ContentItem: {
          w: this.width,
          h: this.innerHeight,
          rect: true,
          rtt: true,
          color: Colors('background').get(),
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: 6,
          },

          ImageWrapper: {
            h: this.innerHeight,
            w: this.innerHeight,
            flexItem: {
              marginRight: 24,
            },
            Image: {
              rtt: true,
              x: 0,
              y: 0,
              h: this.innerHeight,
              w: this.innerHeight,
            },
            ImageCover: {
              rect: true,
              alpha: 0.0001,
              color: Colors('text').get(),
              x: 0,
              y: 0,
              h: this.innerHeight,
              w: this.innerHeight,
            },
            PlayIcon: {
              alpha: 0.0001,
              rect: true,
              rtt: true,
              x: 20,
              y: 20,
              texture: Img('images/icons/play-circle.png').contain(24, 24),
              h: this.innerHeight - 40,
              w: this.innerHeight - 40,
              color: Colors('text').get(),
            },
          },

          Text: {
            h: 120,
            flex: {
              direction: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            },
            Title: {
              color: Colors('text').get(),
              text: {
                text: 'Up Next',
                fontFace: 'Bold',
                fontSize: 18,
                wordWrapWidth: this.width - this.innerHeight - 60,
                maxLines: 2,
              },
              flexItem: {
                marginBottom: 4,
              },
            },
            Description: {
              color: Colors('text').get(),
              text: {
                text: '',
                fontFace: 'Text',
                fontSize: 16,
                wordWrapWidth: this.width - this.innerHeight - 60,
                maxLines: 2,
                lineHeight: 18,
              },
            },
          },
        },
        Timer: {
          x: 0,
          y: this.innerHeight,
          w: this.width,
          h: this.height - this.innerHeight,
          flex: {
            direction: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          Countdown: {
            w: this.innerHeight,
            h: this.height - this.innerHeight,
            text: {
              text: 'In 2s',
              textAlign: 'left',
              verticalAlign: 'middle',
              fontFace: 'Regular',
              fontSize: 16,
            },
          },
          Progress: {
            flexItem: {
              marginLeft: 10,
            },
            rect: true,
            rtt: true,
            color: Colors('text').get(),
            w: 8,
            h: 8,
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: 4,
            },
          },
        },
      },
    }
  }
  override _captureEnter() {
    this.signal('next', 'selected')
  }

  override _firstActive() {
    this._setState(this._contentItem === null ? 'NoContent' : 'Waiting')
  }
  override _active() {
    debug.info('active', this)
  }
  override _inactive() {
    debug.info('inactive')
  }

  private _popped = false

  private _contentItem: ContentItem | null = null
  set next(item: ContentItem | null) {
    debug.info('Next set to', item)
    if (!equal(item, this._contentItem)) {
      debug.info('Not equal', item === null)
      this._setState(item === null ? 'NoContent' : 'Waiting')
      this._contentItem = item
      if (item) {
        const wide = item.images.wide?.getForWidth(120)
        if (wide) {
          this.Image.patch({
            rtt: true,
            texture: Img(wide).cover(
              NextVideoCard.innerHeight,
              NextVideoCard.innerHeight
            ),
            shader: {
              type: Lightning.shaders.RoundedRectangle,
              radius: [0, 6, 6, 0],
            },
          })
          this.Image.on('txLoaded', () => {
            this.patch({ visible: true })
          })
          this.Image.on('txError', () => {
            this.Image.patch({
              visible: false,
            })
            this.patch({
              visible: true,
            })
          })

          debug.info('Patched Image', this.Image)
        } else {
          this.Image.patch({
            visible: false,
          })
        }
        this.Description.patch({
          text: {
            text: item.title,
          },
        })
        this._setState('Waiting')
      } else {
        console.info('NextVideoCard - setting NoContent')
        this._setState('NoContent')
      }
    }
  }
  get next() {
    return this._contentItem
  }

  getPopTime(time: number, duration: number): [number, number] {
    if (isNaN(time) || isNaN(duration) || duration === Infinity)
      return [Infinity, Infinity]
    const debug = Settings.get('app', 'DEBUG_UP_NEXT', false)
    const offset = Settings.get('app', 'UP_NEXT_OFFSET_FROM_END', 0)
    const upNextTimeInSeconds = Settings.get(
      'app',
      'UP_NEXT_TIME_IN_SECONDS',
      10
    )
    let start = duration - upNextTimeInSeconds - offset
    start = debug ? 5 : start < 0 ? 5 : start
    return [start, offset]
  }

  private _focusAnimation: Lightning.types.Animation | null = null
  get focusAnimation(): Lightning.types.Animation {
    if (this._focusAnimation === null) {
      this._focusAnimation = this.animation({
        duration: 0.3,
        actions: [
          {
            t: 'Content.ContentItem',
            p: 'color',
            v: {
              0: Colors('background').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'Content.ContentItem.ImageWrapper.PlayIcon',
            p: 'color',
            v: {
              0: Colors('text').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'Content.ContentItem.ImageWrapper.PlayIcon',
            p: 'alpha',
            v: {
              0: 0.0001,
              1: 1,
            },
          },
          {
            t: 'Content.ContentItem.ImageWrapper.ImageCover',
            p: 'color',
            v: {
              0: Colors('text').get(),
              1: Colors('primaryHighlight').get(),
            },
          },
          {
            t: 'Content.ContentItem.ImageWrapper.ImageCover',
            p: 'alpha',
            v: {
              0: 0.0001,
              1: 0.2,
            },
          },
          {
            t: 'Content.Timer.Progress',
            p: 'color',
            v: { 0: Colors('text').get(), 1: Colors('primaryHighlight').get() },
          },
        ],
      })
    }
    return this._focusAnimation
  }
  override _focus() {
    this.focusAnimation.start()
    debug.info('Timer', this.Timer)
  }
  override _unfocus() {
    this.focusAnimation.stop()
  }
  static override _states(): (typeof NextVideoCard)[] {
    return [
      class NoContent extends this {
        override $enter() {
          debug.info('Entering No Content State')
          this.patch({
            visible: false,
          })
        }
        override $exit() {
          this.patch({
            visible: true,
          })
        }
      },
      class Waiting extends this {
        private _lookToActivate(time: number, duration: number) {
          const [popTime, offset] = this.getPopTime(time, duration)
          if (!this._popped && popTime !== Infinity) {
            if (time > popTime) {
              this._setState('Active', [duration - offset - time])
            }
          }
        }
        override $enter() {
          debug.info('Entering Waiting State')
          const disabled = Settings.get('app', 'UP_NEXT_DISABLED', false)
          if (!disabled) {
            this.stage.application.on(
              'playerTime',
              this._lookToActivate.bind(this)
            )
          }
        }
        override $exit() {
          debug.info('Exiting Waiting State')
          this.stage.application.off('playerTime', this._lookToActivate)
        }
      },
      class Active extends this {
        private _lookToDeactivate(time: number, duration: number) {
          const [popTime] = this.getPopTime(time, duration)
          if (popTime !== Infinity && time < popTime) {
            this._setState('Waiting')
          }
        }
        pause() {
          if (this._timerAnimation?.isPlaying() === true) {
            debug.info('Pausing Animation')
            this._timerAnimation.pause()
          }
        }
        play() {
          debug.info(
            'Played!',
            this._timerAnimation?.isPaused(),
            this._timerAnimation?.state
          )
          if (this._timerAnimation?.isPaused() === true) {
            debug.info('Playing PAused Animation')
            this._timerAnimation.play()
          }
        }
        _watchPlayerState(playerState: PlayerState) {
          debug.info('Watched Player State %s', playerState.paused)
          if (playerState.paused) this.pause()
          else this.play()
        }
        _timerAnimation: Lightning.types.Animation | null = null
        _timerEnded() {
          this.signal('next', 'timeout')
        }
        _animateTimer(duration = 10) {
          debug.info('Animate Timer for %s', duration, this.w * -1, 0)

          this._timerAnimation = this.Progress.animation({
            duration,
            actions: [
              {
                p: 'w',
                v: {
                  0: 8,
                  1: NextVideoCard.width - NextVideoCard.innerHeight - 10,
                },
              },
            ],
          })
          this._timerAnimation.on('finish', this._timerEnded.bind(this))
          this._timerAnimation.on('progress', (p: number) => {
            const timeLeft = Math.ceil(duration - duration * p)
            debug.info('Time Left %s %s', timeLeft, sh(timeLeft * 1000))
            this.Countdown.patch({
              text: {
                text: `In ${sh(timeLeft * 1000)}`,
              },
            })
          })
          this._timerAnimation.start()
        }
        override $enter(_change: ChangeEnter, duration = 10) {
          this.fireAncestors('$disableHidePlayControls', true)
          this.setSmooth('alpha', 1, {
            duration: 0.3,
          })
          debug.info('Progress', this.Progress)
          this._animateTimer(duration)
          this.stage.application.on(
            'playerTime',
            this._lookToDeactivate.bind(this)
          )
          this.stage.application.on(
            'playerState',
            this._watchPlayerState.bind(this)
          )
        }
        override $exit() {
          this._timerAnimation?.stopNow()
          this.fireAncestors('$disableHidePlayControls', false)
          this.stage.application.off('playerTime', this._lookToDeactivate)
          this.stage.application.off('playerState', this._watchPlayerState)
          this.setSmooth('alpha', 0.0001, {
            duration: 0.3,
          })
        }
      },
    ]
  }

  override _captureRight() {
    this.signal('right')
  }
  override _captureDown() {
    this.signal('down')
  }
  override _captureLeft() {
    this.signal('left')
  }
  override _captureUp() {
    this.signal('up')
  }
}
type States = 'Waiting' | 'NoContent' | 'Active'
interface ChangeEnter {
  prevState: States
  newState: States
  sharedState: string
}
