export type Resolver<T extends any = any> = (value: T | PromiseLike<T>) => void
/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isString(val: any): val is string {
  return typeof val === 'string' || val instanceof String
}
/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export function isNumberId(val: any): val is number {
  return Number.isInteger(val)
}
export function isNumber(val: any): val is number {
  return !isNaN(val) && typeof val === 'number'
}
export function isFunction(value: any) {
  return typeof value === 'function'
}
export type FilterNotStartingWith<
  Set,
  Needle extends string,
> = Set extends `${Needle}${infer _X}` ? never : Set
export type PublicRecordSet<T> = Pick<T, FilterNotStartingWith<keyof T, '_'>>

export function parseEnum(_enum: any) {
  const allValues: (string | number)[] = Object.values(_enum)
  const properties: string[] = allValues.slice(
    0,
    allValues.length / 2
  ) as string[]
  const values: (string | number)[] = allValues.slice(allValues.length / 2)
  const enumObj: Record<string, number | string> = {}

  properties.forEach((prop, index) => {
    enumObj[prop] = values[index]
  })

  return enumObj
}

export function getEnumNamesAndValues<T extends number | string>(_enum: any) {
  const obj = parseEnum(_enum)
  return Object.keys(obj).map(n => ({ name: n, value: obj[n] as T }))
}

export function getEnumNames(_enum: any) {
  if (Object.keys(_enum).length > 0) return Object.keys(_enum) as string[]
  return Object.keys(parseEnum(_enum)) as string[]
}

export function getEnumValues<T extends number | string>(_enum: any) {
  if (Object.keys(_enum).length > 0) return Object.values(_enum) as T[]
  return Object.values(parseEnum(_enum)) as T[]
}
