import {
  AdeButtonCollection,
  Debugger,
  MainMenu,
  ScrollableText,
  getStoredTheme,
} from '@adiffengine/elements'
import { CoordinateDimensions, setsByY } from '@adiffengine/engine-types'
import { Colors, Img, Lightning, Router, Settings } from '@lightningjs/sdk'

import { MituSettingsText, MituTextKey } from './mitu_settings_text'
const debug = new Debugger('mitu_settings_page')
export interface MituSettingsPageTypeConfig
  extends Lightning.Component.TypeConfig {
  IsPage: true
}
interface MenuButtonPayload {
  page: MituTextKey
}
export interface MituSettingsPageTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Content: {
    Background: object
    AboutLogo: {
      Logo: object
    }
    About: {
      CurrentText: typeof ScrollableText
    }
    MenuBar: {
      SettingsMenu: typeof AdeButtonCollection<MenuButtonPayload>
      VersionNumber: object
    }
  }
}

export class MituSettingsPage
  extends Lightning.Component<
    MituSettingsPageTemplateSpec,
    MituSettingsPageTypeConfig
  >
  implements
    Lightning.Component.ImplementTemplateSpec<MituSettingsPageTemplateSpec>
{
  public IsPage = true
  Content = this.getByRef('Content')!
  MenuBar = this.Content.getByRef('MenuBar')!
  SettingsMenu = this.MenuBar.getByRef('SettingsMenu')!
  About = this.Content.getByRef('About')!
  VersionNumber = this.MenuBar.getByRef('VersionNumber')!

  CurrentText = this.About.getByRef('CurrentText')!
  static override _template(): Lightning.Component.Template<MituSettingsPageTemplateSpec> {
    const theme = getStoredTheme()
    const {
      components: { MainMenuConfig },
    } = theme
    return {
      x: 0,
      y: 0,
      w: 1920,
      h: 1080,
      Content: {
        x: 100 + MainMenu.widthClosed, // 80 margin left, menu, 40 margin right to hero.
        y: 80,
        Background: {
          x: 0,
          y: 0,
          w: 1920 - (MainMenu.widthClosed + 220), // 1920 - 96 - 180
          h: 1080 - 160,
          rect: true,
          shader: {
            type: Lightning.shaders.RoundedRectangle,
            radius: theme.components.MainMenuConfig.radius,
          },
          color: Colors(MainMenuConfig.backgroundColor).get(),
        },
        AboutLogo: {
          x: 80,
          y: 80,
          alpha: 1,
          w: 320,
          flex: {
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          Logo: {
            texture: Img('images/mitu_logo_unspaced.png').contain(320, 320),
            rtt: true,
          },
        },
        About: {
          x: 480,
          y: 80,
          w: 1000,
          CurrentText: {
            x: 0,
            y: 0,
            w: 1000,
            h: 700,
            type: ScrollableText,
            textSetup: {
              text: MituSettingsText.about_us.text,
              headerText: MituSettingsText.about_us.headerText,
            },
            signals: {
              left: '_menuFocused',
              scrollable: '_canScroll',
            },
          },
          flex: {
            direction: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
        },
        MenuBar: {
          x: 80,
          y: 240,
          w: 360,
          flex: {
            direction: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
          },
          SettingsMenu: {
            type: AdeButtonCollection<MenuButtonPayload>,
            buttons: Object.entries(MituSettingsText).map(([key, value]) => ({
              buttonText: value.headerText,
              payload: {
                page: key,
              },
            })),
            justifyContent: 'flex-start',
            buttonSize: {
              h: 80,
              w: 360,
            },
            spacing: 40,
            signals: {
              selected: '_setCurrentText',
              left: '_settingsLeft',
              right: '_textFocused',
            },
          },
          VersionNumber: {
            w: 200,
            color: Colors('white').alpha(0.75).get(),
            text: {
              text: '',
              fontFace: 'Regular',
              fontSize: 14,
              lineHeight: 40,
              textAlign: 'center',
              verticalAlign: 'middle',
            },
            flexItem: {
              marginTop: 20,
            },
          },
        },
      },
    }
  }

  _firstActive(): void {
    this.currentText = 'about_us'
    const version = Settings.get('app', 'version', null)

    if (version !== null) {
      const text = `Version: ${version}`
      this.VersionNumber.patch({
        text: {
          text,
        },
      })
    }
  }
  _setCurrentText(payload: MenuButtonPayload) {
    const { page } = payload
    debug.info('Set Current Text %s', page)
    this.currentText = page
  }
  _currentText: MituTextKey = 'about_us'
  set currentText(key: MituTextKey) {
    if (key !== this._currentText) {
      this._currentText = key
      this.CurrentText.patch({
        textSetup: {
          text: MituSettingsText[key].text,
          headerText: MituSettingsText[key].headerText,
          subheaderText: MituSettingsText[key].subheaderText,
        },
      })
    }
  }

  override _getFocused(): Lightning.Component {
    return this.SettingsMenu
  }

  _settingsLeft(coords?: CoordinateDimensions) {
    if (setsByY(this.widgets['mainmenu'])) {
      this.widgets['mainmenu'].setClosestByY(coords)
      Router.focusWidget('MainMenu')
    }
  }

  override _handleBack() {
    return true
  }

  // Monitor the main menu
  override _active() {
    this.stage.application.emit(
      'screenView',
      'settings',
      'mitu_settings_page',
      'Mitú Settings'
    )
    const menuOpen = this.fireAncestors('$menuOpen')
    this.Content.patch({
      x: menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
    })
    this.fireAncestors('$enableMainMenuPointer', false)
  }

  override _init() {
    this.stage.application.on('menuOpen', (menuOpen: boolean) => {
      this.Content.setSmooth(
        'x',
        menuOpen ? 120 + MainMenu.width : 120 + MainMenu.widthClosed,
        { duration: 0.2 }
      )
    })
  }
  _scrollable: boolean = false
  _canScroll(scrollable: boolean) {
    this._scrollable = scrollable
  }
  _menuFocused() {
    this._setState('MenuFocused')
  }
  _textFocused() {
    if (this._scrollable) {
      this._setState('TextFocused')
    }
  }
  static override _states() {
    return [
      class MenuFocused extends this {
        override _getFocused() {
          return this.SettingsMenu
        }
      },
      class TextFocused extends this {
        override _getFocused() {
          return this.CurrentText
        }
      },
    ]
  }
}
