import {
  AdvancedListPosition,
  ContentItem,
  CoordinateDimensions,
  DirectionalSignalMap,
  FocusableMixin,
  ID,
  RemoteDirection,
  SetsByX,
  XListPosition,
  YListPosition,
  canGetFocusCoordinates,
  setsByX,
} from '@adiffengine/engine-types'
import { Lightning } from '@lightningjs/sdk'
import { List } from '@lightningjs/ui'
import equal from 'fast-deep-equal/es6'
import { Debugger, getCoordinateDimensions, registerHoverable } from '../../lib'
import {
  defer,
  isBoolean,
  isFunction,
  isNumber,
  safeHash,
} from '../../lib/utils'
import { MainMenu } from '../MainMenu'
import { AdvancedBoxCard } from './AdvancedBoxCard'
import { AdvancedWideCard } from './AdvancedWideCard'
import { GRID_COLUMN_ID, GridState } from './gridState'

export type AdvancedGridCardTypes =
  | typeof AdvancedBoxCard
  | typeof AdvancedWideCard

const debug = new Debugger('AdvancedGrid')
debug.enabled = false

export interface GridRow extends SetsByX {
  content: ContentItem[]
}

export interface LightningConstructor<
  T extends Lightning.Component = Lightning.Component,
> {
  new (...args: any[]): T
  getRowHeight?(w: number, patch: Lightning.Element.NewPatchTemplate): number
  height?: number
}

export interface DynamicHeightGridRow extends LightningConstructor {
  getRowHeight(w: number, patch: Lightning.Element.NewPatchTemplate): number
}
export interface StaticHeightGridRow extends LightningConstructor {
  height: number
}

export function isDynamicHeightGridRow(
  x: LightningConstructor
): x is DynamicHeightGridRow {
  return x && isFunction(x.getRowHeight)
}

export function isStaticHeightGridRow(
  x: LightningConstructor
): x is StaticHeightGridRow {
  return isNumber(x.height)
}

export type AdvancedGridPatch =
  Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec>
export interface AdvancedGridTemplateSpec
  extends Lightning.Component.TemplateSpec {
  requestId: ID
  items: Lightning.Element.PatchTemplate<AdvancedGridTemplateSpec>[]
  Test: object
  AdvancedGridContent: {
    AdvancedGridColumn: typeof List
  }
  spacing: number
}

export interface AdvancedGridRow extends Lightning.Component {
  h: number
}

export interface AdvancedGridTypeConfig extends Lightning.Component.TypeConfig {
  SignalMapType: FocusableMixin<DirectionalSignalMap>
}

export class AdvancedGrid
  extends Lightning.Component<AdvancedGridTemplateSpec, AdvancedGridTypeConfig>
  implements
    Lightning.Component.ImplementTemplateSpec<AdvancedGridTemplateSpec>
{
  static bottomMargin = 80

  AdvancedGridContent = this.getByRef('AdvancedGridContent')!
  List = this.AdvancedGridContent.getByRef('AdvancedGridColumn')!
  // c<T extends Component.Constructor>(settings: Element.NewPatchTemplate<T>): InstanceType<T>;
  gridItem<T extends LightningConstructor>(
    item: Lightning.Element.NewPatchTemplate<T>
  ): Lightning.Element.NewPatchTemplate<T> {
    const update: Record<string, unknown> = {}
    if (isDynamicHeightGridRow(item['type'])) {
      update['h'] = item['type'].getRowHeight(this.w, item)
    } else if (isStaticHeightGridRow(item['type'])) {
      update['h'] = item['type'].height
    } else {
      console.warn('Grid item does not have a height setter', item)
    }
    return {
      ...item,
      ...update,
      gridRowInstance: true,
      signals: {
        up: this._up.bind(this),
        down: this._down.bind(this),
        left: this._left.bind(this),
        right: this._right.bind(this),
      },
    }
  }

  static fadeOffset = 80
  override get id() {
    return 'AdvancedGrid'
  }
  static override _template(): Lightning.Component.Template<AdvancedGridTemplateSpec> {
    return {
      w: 1920 - (MainMenu.widthClosed + 180),
      h: 1080 - 160,

      AdvancedGridContent: {
        // x: -this.fadeOffset,
        y: -this.fadeOffset,
        w: (w: number) => w,
        h: (h: number) => h + this.fadeOffset * 2,
        shader: {
          type: Lightning.shaders.FadeOut,
          top: this.fadeOffset,
          bottom: this.fadeOffset,
        },
        AdvancedGridColumn: {
          x: 40,
          y: 40,
          type: List,
          w: (w: number) => w - this.fadeOffset * 2,
          h: (h: number) => h - this.fadeOffset * 2,
          spacing: 100,
          direction: 'column',
        },
      },
    }
  }

  override _construct() {
    this._handleMainMenuScrollButtons =
      this._handleMainMenuScrollButtons.bind(this)
  }

  get currentFocusList(): Lightning.Component | null {
    const current = this.List.items[this.List.index]
    debug.info('Current Focus List for %s', this.List.index, current)
    return current ? (current as Lightning.Component) : null
  }

  getCurrentFocusCoordinates(): CoordinateDimensions | null {
    const canGet = canGetFocusCoordinates(this.currentFocusList)
    debug.info('can get coordinates', canGet)
    if (canGetFocusCoordinates(this.currentFocusList)) {
      return this.currentFocusList.currentFocusCoordinates()
    }
    return null
  }

  _handleMainMenuScrollButtons(direction: RemoteDirection) {
    const currentCoordinates = this.getCurrentFocusCoordinates()
    debug.info('Current Coordinates', currentCoordinates)
    if (direction === 'up') {
      this._up(currentCoordinates)
      this.List.previous()
    } else if (direction === 'down') {
      this._down(currentCoordinates)
      this.List.next()
    }
  }

  override _init() {
    registerHoverable('AdvancedGrid', this)
  }
  override _active() {
    this.stage.application.on(
      'mainMenuScrollButton',
      this._handleMainMenuScrollButtons
    )
  }
  override _inactive() {
    this.stage.application.off(
      'mainMenuScrollButton',
      this._handleMainMenuScrollButtons
    )
  }

  set spacing(spacing: number) {
    this.List.patch({ spacing })
  }

  get spacing() {
    return this.List.spacing
  }

  private _items: Lightning.Component[] = []
  set items(items: Lightning.Component[]) {
    if (!equal(items, this._items)) {
      // We're deferring this because we want to handle
      // Grid cache if requestId is set first...
      this._items = items
      this.List.clear()
      this.List.patch({
        items: this.items.map((i, idx) => ({
          ...i,
          signals: {
            ...i.signals,
            reposition: () => {
              defer(() => {
                debug.info('Got list reposition signal')
                this.List.reposition()
              })
            },
            hovered: () => {
              this.setHoverIndex(idx)
            },
          },
        })),
      })
    }
  }

  get listLength() {
    return (this.List as Lightning.Component).childList.length
  }

  setHoverIndex(idx: number) {
    this.List.setIndex(idx)
  }

  get items() {
    return this._items
  }
  get listPosition(): YListPosition {
    const {
      index,
      scrollTransition: { targetValue },
    } = this.List
    return {
      index,
      yOffset: targetValue,
    }
  }

  renderList() {
    const coords = getCoordinateDimensions(this)
    debug.info('Rendering Grid', coords)
    this.AdvancedGridContent.patch({
      x: -40,
      y: -40,
      w: this.w + 80,
      h: this.h + 80,
    })
    this.List.patch({
      x: 40,
      y: 40,
      h: coords.height,
      w: coords.width,
    })
  }

  override _enable() {
    this.renderList()
    debug.info(
      'List Coords',
      getCoordinateDimensions(this.List),
      this.List,
      this.List.items
    )
  }
  override _unfocus() {
    this.fireAncestors(
      '$cacheAdvancedGridPosition',
      GRID_COLUMN_ID,
      this.listPosition
    )
  }
  override _disable() {
    this.fireAncestors(
      '$cacheAdvancedGridPosition',
      GRID_COLUMN_ID,
      this.listPosition
    )
  }

  _up(coords?: CoordinateDimensions | null) {
    if (this.List.index > 0 && coords) {
      const next = this.List.items[this.List.index - 1]
      if (setsByX(next)) next.setClosestByX(coords)
    }
  }

  _down(coords?: CoordinateDimensions | null) {
    if (this.List.index < this.List.items.length - 1) {
      const next = this.List.items[this.List.index + 1]
      if (setsByX(next)) next.setClosestByX(coords)
    }
  }

  _left(coords?: CoordinateDimensions | null) {
    debug.info('Got left, signaling')
    const response = this.signal('left', coords)
    return isBoolean(response) ? response : false
  }
  _right(coords?: CoordinateDimensions | null) {
    const response = this.signal('right', coords)
    return isBoolean(response) ? response : false
  }

  private _requestId: ID = safeHash()
  set requestId(id: ID) {
    if (id !== this._requestId) {
      this._requestId = id
      const saved = this.fireAncestors('$gridState', id)
      this._gridState = new GridState(saved ?? id)
    }
  }
  get requestId() {
    return this._requestId
  }

  private _gridState: GridState = new GridState(this.requestId)
  $cacheAdvancedGridPosition(id: ID, position: AdvancedListPosition) {
    if (id === GRID_COLUMN_ID) {
      this._gridState.setColumnState(position as YListPosition)
    } else {
      this._gridState.setRowState(id, position as XListPosition)
    }
    this.fireAncestors(
      '$gridState',
      this.requestId,
      this._gridState.serialize()
    )
  }
  override _getFocused() {
    return this.List
  }
}
