import { Colors, Img, Lightning, Registry } from '@lightningjs/sdk'

import { pathJoin } from '../../lib'
export interface ProgressTabTemplateSpec
  extends Lightning.Component.TemplateSpec {
  Icon: object
  Content: {
    Text: object
  }
}
export interface ProgressTabTypeConfig extends Lightning.Component.TypeConfig {}
export class ProgressTab
  extends Lightning.Component<ProgressTabTemplateSpec, ProgressTabTypeConfig>
  implements Lightning.Component.ImplementTemplateSpec<ProgressTabTemplateSpec>
{
  Content = this.getByRef('Content')!
  Text = this.Content?.getByRef('Text')!
  static override _template(): Lightning.Component.Template<ProgressTabTemplateSpec> {
    return {
      alpha: 0.00001,
      w: 200,
      h: 24,
      Icon: {
        w: 24,
        h: 24,

        color: Colors('text').alpha(0.8).get(),
        texture: Img(pathJoin(['images/icons', 'caret-down.png'])).contain(
          24,
          24
        ),
        y: 16,
        x: 100,
        mountX: 0.5,
      },
      Content: {
        w: 200,
        h: 24,
        rect: true,
        rtt: true,
        color: Colors('text').alpha(0.8).get(),
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: 12,
        },
        flex: {
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },
        Text: {
          color: Colors('background').get(),
          text: {
            textAlign: 'center',
            fontFace: 'Regular',
            fontSize: 16,
            text: '00:00/00:00',
          },
          y: 0,
          x: 8,
        },
      },
    }
  }

  show() {
    this.clearTimeout()
    this.setSmooth('alpha', 1)
  }
  private _timeout: ReturnType<typeof Registry.setTimeout> | undefined =
    undefined
  clearTimeout() {
    if (this._timeout != null) {
      this._timeout = Registry.clearTimeout(this._timeout)
    }
  }
  hide(ms: number = 0) {
    Registry.setTimeout(() => {
      this._timeout = undefined
      this.setSmooth('alpha', 0.00001)
    }, ms)
  }

  setTime(text: string) {
    this.Text.patch({ text: { text } })
  }
}
