import { PartialDeep } from 'type-fest'
import { z } from 'zod'
import { getEnumValues, isString } from './utils'

export enum EsVersion {
  Es6 = 'es6',
  Es5 = 'es5',
}

export enum DeployEnvironments {
  Testing = 'testing',
  Staging = 'staging',
  Production = 'production',
  Development = 'development',
}

export enum ReleaseTypes {
  Patch = 'patch',
  Minor = 'minor',
  Major = 'major',
  None = 'none',
}
export type ReleaseType = `${ReleaseTypes}`

export type DeployEnvironment = `${DeployEnvironments}`

export enum DeviceTypes {
  X1 = 'x1',
  Samsung = 'samsung',
  LG = 'lg',
  Android = 'android',
  SmartTV = 'smart-tv',
  SamsungEs5 = 'samsung-es5',
  SamsungEs6 = 'samsung-es6',
  SamsungEs2015 = 'samsung-es2015',
}

export enum SamsungDeviceTypes {
  SamsungEs5 = 'samsung-es5',
  SamsungEs6 = 'samsung-es6',
  SamsungEs2015 = 'samsung-es2015',
}
const v = Object.values(SamsungDeviceTypes)
export type SamsungDeployType = `${SamsungDeviceTypes}`
export function isSamsungDeployType(x: unknown): x is SamsungDeviceTypes {
  return isString(x) && getEnumValues(SamsungDeviceTypes).includes(x)
}

export function isValidDeviceType(x: unknown): x is DeviceTypes {
  return isString(x) && getEnumValues(DeviceTypes).includes(x)
}
export type DeviceType = `${DeviceTypes}`

export enum LightningBundlers {
  Esbuild = 'esbuild',
  Rollup = 'rollup',
}
export enum LightingBuildCommand {
  Dist = 'dist',
  Build = 'build',
}
export type LightningBundler = `${LightningBundlers}`

export const appSettingsSchema = z.object({
  debug: z.boolean().default(false),
  api_url: z.string().url(),
  search_url: z.string().url().optional(),
  version: z.string().optional(),
  app: z.string().optional(),
  env: z.nativeEnum(DeployEnvironments).default(DeployEnvironments.Production),
  platform: z.nativeEnum(DeviceTypes).default(DeviceTypes.SmartTV).optional(),
  keys: z.record(z.string(), z.string()).optional(),
  APP_MUX_ENV_KEY: z.string().optional(),
  APP_MUX_DEBUG: z.boolean().optional(),
  APP_SHORT_NAME: z.string(),
  APP_ERROR_CODE: z.string(),
  SCREENSAVER_ENABLED: z.boolean().default(false),
  SCREENSAVER_TIMEOUT_IN_MINUTES: z.number().min(10).max(60).default(30),
  ARE_YOU_STILL_THERE_TIMEOUT_IN_SECONDS: z
    .number()
    .int()
    .min(30)
    .max(600)
    .default(30),
  SCREENSAVER_TITLE: z.string().optional(),
  SCREENSAVER_TEXT: z.string().optional(),
  ADS_ENABLED: z.boolean().default(true),
  HOW_LONG_TO_WAIT_IN_SECONDS_FOR_A_STILL_THERE_CHECK: z
    .number()
    .min(180)
    .max(600)
    .optional(),
  MAX_VIDEOS_BEFORE_CHECK: z.number().int().positive().default(1),
  UP_NEXT_TIME_IN_SECONDS: z.number().min(3).max(60).optional(),
  UP_NEXT_OFFSET_FROM_END: z.number().min(0).max(120).optional(),
  UP_NEXT_DISABLED: z.boolean().default(false),
  DEBUG_UP_NEXT: z.boolean().default(false),
  SENTRY_DSN: z.string().url().optional().nullable(),
  FIREBASE_APP_ID: z.string().optional(),
  buildVersion: z.string().optional(),
  gitInfo: z.string().optional(),
  buildDate: z.string().optional(),
  commitDate: z.string().optional(),
  FIREBASE_MEASUREMENT_ID: z.string().optional(),
  PLAUSIBLE_DOMAIN: z.string().optional(),
  FORCE_GA_TAG: z.boolean().default(false),
  enablePointer: z.boolean().default(false),

  stage: z
    .record(z.string(), z.union([z.string(), z.boolean(), z.number()]))
    .optional(),
})

export type AppSettings = z.infer<typeof appSettingsSchema>

const platformSettingsSchema = z.object({
  path: z.string(),
  log: z.boolean().default(false),
  clearColor: z.union([z.number(), z.string()]).optional(),
  showVersion: z.boolean().default(false),
  proxyUrl: z.string().url().optional(),
  router: z
    .record(z.string(), z.union([z.boolean(), z.number(), z.string()]))
    .optional(),
  inspector: z.boolean().default(false),
})
export type PlatformSettings = z.infer<typeof platformSettingsSchema>

export const settingsSchema = z.object({
  appSettings: appSettingsSchema,
  platformSettings: platformSettingsSchema,
  appData: z
    .record(z.string(), z.union([z.string(), z.number(), z.boolean()]))
    .optional(),
})
export type SettingsFile = z.infer<typeof settingsSchema>
export type PlatformSettingsFile = {
  appSettings: Partial<AppSettings>
  platformSettings?: Partial<PlatformSettings>
}
export type PartialSettingsFile = {
  appSettings?: Partial<AppSettings>
  platformSettings?: Partial<PlatformSettings>
}
export type SettingsOverride = {
  appSettings: Partial<AppSettings>
  platformSettings: Partial<PlatformSettings>
}
export type EnvironmentSettingsFile = PartialDeep<SettingsFile>
export function isDeployEnvironment(x: unknown): x is DeployEnvironments {
  return (
    (typeof x === 'string' || x instanceof String) &&
    (Object.values(DeployEnvironments) as string[]).includes(x.toString())
  )
}
